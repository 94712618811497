import React from 'react';
import ImageWrapper from './ImageWrapper';
import JsonModal from '../../pages/visits/components/details/json-modal';

const VISIBLE_INDICATORS_COUNT = 8;
const KEY_CODE = {
  LEFT: 37,
  RIGTH: 39
};
const ret = Math.round(VISIBLE_INDICATORS_COUNT / 2)

function ImageViewer({ showPreview, images, showIndex, activeIndex, setActiveIndex, showProductButtons }) {
  const [mounted, setMounted] = React.useState(true);
  const indicatorVisible = images.length > 1;
  
  //JSON MODAL
  const [jsonModal, setJsonModal] = React.useState(false);
  const [jsonModalTitle, setJsonModalTitle] = React.useState('');
  const [activeBoxIndex, setActiveBoxIndex] = React.useState();

  const toggleJsonModal = (title, activeBoxIndex) => {
    setJsonModalTitle(title);
    setJsonModal(!jsonModal);
    setActiveBoxIndex(activeBoxIndex);
  }

  const renderIndicators = (list) => {
    const length = list.length;
    return list.map((item, index) => {
      const isActive = activeIndex === index;
      const itemInvisible = length > VISIBLE_INDICATORS_COUNT && (index < Math.min(length - VISIBLE_INDICATORS_COUNT - 1, activeIndex - ret) || index > Math.max(activeIndex + ret, VISIBLE_INDICATORS_COUNT));
      const itemCls = `indicators-item ${isActive ? 'active' : ''} ${itemInvisible ? 'invisible' : ''} ${showPreview ? 'preview' : ''}`;

      return (
        <div key={index} className={itemCls} onClick={() => itemControl(index)} >
          {showPreview && (
            <div className="image" style={{ background: `url(${item.src})` }}>
            </div>
          )}
        </div>
      )
    });
  }

  const itemControl = (index) => {
    if (index === activeIndex)
      return;
    setActiveIndex(index);
  }

  const onPrev = () => {
    let index = (activeIndex + images.length - 1) % images.length;
    itemControl(index);
  }

  const onNext = () => {
    let index = (activeIndex + 1) % images.length;
    itemControl(index);
  }

  const onKeyDown = (e) => {
    if (!mounted)
      return;
    e.stopPropagation();
    switch (e.which || e.keyCode) {
      case KEY_CODE.LEFT:
        onPrev();
        break;
      case KEY_CODE.RIGTH:
        onNext();
        break;
    }
  }

  React.useEffect(() => {
    setMounted(true);
    document.documentElement.addEventListener("keydown", onKeyDown);
    return () => {
      setMounted(false);
      document.documentElement.removeEventListener("keydown", onKeyDown);
    }
  }, []);

  return (
    <div className='react-image-viewer'>
      <ImageWrapper showIndex={showIndex} index={`${activeIndex + 1}/${images.length}`} image={images[activeIndex]} showProductButtons={showProductButtons} openContentJsonModal={toggleJsonModal} />
      {indicatorVisible && (
        <div className="direction-control-button">
          <div className="prev-button button" onClick={onPrev}>
            <div className="bar">
            </div>
          </div>

          <div className="next-button button" onClick={onNext}>
            <div className="bar">
            </div>
          </div>

          <div className="indicators">
            {indicatorVisible && renderIndicators(images)}
          </div>
        </div>
      )}

      {activeBoxIndex && <JsonModal value={jsonModalTitle} isOpen={jsonModal} toggle={toggleJsonModal} jsonContent={jsonModalTitle === "MMAI"? images[activeIndex]?.boxesJson[activeBoxIndex] : images[activeIndex]?.pricesJson[activeBoxIndex]} />}
    </div>
  )
}

export default ImageViewer;
