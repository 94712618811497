import React, { Fragment, useState } from 'react';
import { PlusCircle, X } from 'react-feather';
import useForm from '../../../hooks/useForm';
import './style.css';

import Surveys from './surveys';
import { useAuth0 } from "@auth0/auth0-react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useService } from "../../../hooks/useService";
import Datatable from "../../../components/tables/table";
import Input from '../../../components/common/form/input.js';
import emptyList from '../../../assets/images/emptylist.png';
import Breadcrumb from '../../../components/common/breadcrumb';
import SimulationModal from './simulation/simulation-modal.js';
import { Alert } from "../../../components/common/modals/modals.js";
import SimulationResponse from './simulation/simulation-response.js';
import authorizedIcon from '../../../assets/images/ic_authorized.png';
import { getLocation, formatDate, states } from "../../../utils/helper.js";
import MultiSelect from "../../../components/common/form/multi-select/index";
import { generateCombosColumns as columns } from "../../../utils/tablesHelper";
import RoutePresetConfirmationModal from './preset/routePresetConfirmationModal';
import GoogleApiWrapper from '../../../components/common/pointer-locations-map/map-filter';

const CombosGenerate = () => {

    const service = useService();
    const [loading, setLoading] = useState(false);

    // table state
    const { user } = useAuth0();
    const [data, setData] = React.useState([]);
    const [selectedPdvs, setSelectedPdvs] = React.useState([]);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [pageCurrent, setCurrentPage] = React.useState(0);
    const [selectedTablePage, setSelectedTablePage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [recordsFiltered, setRecordsFiltered] = React.useState(0);

    // filters state
    const [pvdTypes, setPvdTypes] = React.useState([]);
    const [cityFilter, setCityFilter] = React.useState("");
    const [stateFilter, setStateFilter] = React.useState(-1);
    const [presetFilter, setPresetFilter] = React.useState(-1);
    const [regionFilter, setRegionFilter] = React.useState("");
    const [typeFilter, setTypeFilter] = React.useState("0");
    const [latitude, setLatitude] = React.useState(0);
    const [longitude, setLongitude] = React.useState(0);

    const pdvValue = useForm('number');
    const distanceFilter = useForm('number');
    const maxDistance = useForm('number');
    const expirationDate = useForm('date');
    const [selectedCampaigns, setSelectedCampaigns] = React.useState([]);
    const [selectedUserGroups, setSelectedUserGroups] = React.useState([]);

    // surveys
    const [surveys, setSurveys] = React.useState([]);
    const [campaigns, setCampaigns] = React.useState([]);
    const [usersGroups, setUsersGroups] = React.useState([]);

    // simulation
    const [simulationModal, setSimulationModal] = useState(false);
    const [allData, setAllData] = useState([]);
    const [confirmedModalData, setConfirmedModalData] = useState([]);
    const [confirmedSurveys, setConfirmedSurveys] = useState([]);
    const [openResponseModal, setOpenResponseModal] = useState(false);

    // presets
    const [presets, setPresets] = React.useState([]);
    const [routePresetConfirmationIsOpen, setRoutePresetConfirmationIsOpen] = React.useState(false);

    const addSurvey = () => {
        let dt = new Date();
        let newSurvey = {
            id: "newSurvey" + surveys.length + dt.getMilliseconds() + dt.getMinutes() + dt.getHours(),
            number: surveys.length +1,
            title: '',
            answerType: 0,
            AIFilterKeywords: ''
        };

        var items = [...surveys];
        items.push(newSurvey);
        setSurveys([...items]);
    }

    const getCampaigns = async () => {
        await service.get("/api/campaign/simplelist")
        .then(async response => {

            let result = response?.data?.data?.map(c => ({
                label: (<div className='combo-panel-select'>
                    <p>{c.name}&nbsp;</p>
                    <b>{formatDate(new Date(c.startDate))} a {formatDate(new Date(c.endDate))}</b>
                </div>),
                value: c.id,
                text: c.name,
            }))

            setCampaigns(result);
        });
    }

    const getUsersGroups = async () => {
        await service.get("/api/usersgroup/simplelist")
        .then(async response => {

            let result = response?.data?.data?.map(c => ({
                label: c.name + ' usuários',
                value: c.id,
                text: c.name + ' usuários',
            }))

            setUsersGroups(result);
        });
    }

    const getAuthorizationColumn = (pdvId, authorization) => {

        return (<div className="hasTooltip">
            <div className="align-center">
                {/* // onClick={() => {
                //     setApproveModalPDVId(pdvId);
                //     setShowAuthorizeModal(true);
                // }} */}
                <img 
                    className={`authorization-image ${authorization == null && 'authorization-image-opacity'}`}
                    src={authorizedIcon}>                    
                </img>
            </div>

            {authorization != null && <div className="tooltip-pvd-parent ml-50">
                <div className="tooltip-pdv-item">
                    <h3>Detalhes</h3>
                    <span>Autorizado por: </span>
                    <span><b className="main-color">{authorization.authorizedByName}</b></span>

                    <br/>
                    <span>Em: </span>
                    <span><b className="main-color">{new Date(authorization.whenDate).toLocaleDateString('pt-BR')}</b></span>
                </div>
            </div>}
        </div>);
    }

    React.useEffect(() => {
        async function init() {
            getLocation(setLatitude, setLongitude);
            addSurvey();
            getTypes();
            getPresets();
            if(user){
                getCampaigns();
                getUsersGroups();
            }
        }
            init()
    }, [user]);

    const cleanInputs = () => {
        getLocation(setLatitude, setLongitude);
        setCityFilter("");
        setStateFilter(-1);
        setPresetFilter(-1);
        setRegionFilter("");
        setTypeFilter("0");
        distanceFilter.setValue();
        pdvValue.setValue();
        maxDistance.setValue();
        expirationDate.setValue("");
        setSelectedCampaigns([]);
        setSelectedUserGroups([]);
    }

    const getTypes = async () => {

        await service.get("/api/pointofsaletype/list")
        .then(async response => {
            if (response?.data?.status !== 200) {
                return await Alert("Por favor tente novamente mais tarde!", "Falha ao buscar os tipos de PDVs", "error");
            }
            setPvdTypes(response.data.data);
        });
    }

    const getPresets = async () => {
        await service.get("/api/RoutePreset/list")
            .then(async response => {
                const result = response?.data?.data;
                setPresets(result.map(r => ({
                    label: r.name,
                    name: r.name,
                    value: r.id,
                    id: r.id
                })))
            });
    }

    const getPresetContent = async (presetId) => {
        await service.get(`/api/RoutePreset/details/${presetId}`)
        .then(async response => {
            
            let result = response?.data?.data;
            if(result.expirationDate) {
                var date = new Date(result.expirationDate);
                const year = date?.getFullYear();
                const month = String(date?.getMonth() + 1).padStart(2, '0');
                const day = String(date?.getDate()).padStart(2, '0');
                expirationDate.setValue(`${year}-${month}-${day}`);
            }

            distanceFilter.setValue(result.distance);
            maxDistance.setValue(result.maxDistance);

            if(result.pointsOfSaleFilterState)
                setStateFilter(result.pointsOfSaleFilterState);

            if(result.pointsOfSaleCityFilter)
                setCityFilter(result.pointsOfSaleCityFilter);

            if(result.pointsOfSaleRegionFilter)
                setRegionFilter(result.pointsOfSaleRegionFilter);

            if(result.pointsOfSaleLatitudeFilter) {
                setLatitude(result.pointsOfSaleLatitudeFilter);
            } else {
                setLatitude(0);
            }

            if(result.pointsOfSaleLongitudeFilter) {   
                setLongitude(result.pointsOfSaleLongitudeFilter);
            } else {
                setLongitude(0);
            }

            if(result.pointsOfSaleTypeIdFilter)
                setTypeFilter(result.pointsOfSaleTypeIdFilter);

            if(result.pointsOfSaleSelected) {
                setSelectedPdvs(result.pointsOfSaleSelected);
                // get point of sales with the filters
                getPVDs();                
            }        

            let questions = result.questions.map((question) => 
            ({
                title: question.title,
                number: question.number,
                answerType: question.answerType,
                AIFilterKeywords: question.aiFilterKeywords,
                id: "newSurvey" + question.number + new Date().getMilliseconds() + new Date().getMinutes() + new Date().getHours()
            }));
                
            if(questions.length > 0) {
                pdvValue.setValue(parseInt(result.questions[0].value))
            }
            setSurveys([...questions]);

            let userGroupsResult = [];
            let campaignsResult = [];

            result.usersGroupsIds.forEach(groupId => {
                let index = usersGroups.findIndex(g => g.value === groupId);
                if(index !== -1) 
                    userGroupsResult.push({
                        value: groupId,
                        label: usersGroups[index].label,
                        text: usersGroups[index].text
                })
            });
            setSelectedUserGroups([...userGroupsResult]);

            result.campaignsIds.forEach(campaignId => {
                let index = campaigns.findIndex(g => g.value === campaignId);
                if(index !== -1) 
                    campaignsResult.push({
                        value: campaignId,
                        label: campaigns[index].label,
                        text: campaigns[index].text
                })
            });
            setSelectedCampaigns([...campaignsResult]);
        });
    }

    const validateFields = () => {
        pdvValue.validate();
        distanceFilter.validate();
        maxDistance.validate();
        expirationDate.validate();
    }

    const sendSimulation = async() => {
        if(selectedCampaigns.length === 0) {
            return await Alert("Por favor, selecione ao menos 1 campanha", "Dados inválidos", "error");
        }

        if(surveys.length === 0) {
            return await Alert("Por favor, cadastre ao menos 1 pergunta", "Dados inválidos", "error");
        }

        if(selectedPdvs.length === 0){
            return await Alert("Por favor, selecione ao menos 1 Ponto de Venda", "Dados inválidos", "error");
        }

        if(pdvValue.value === ""){
            await Alert("O valor por ponto de venda deve ser informado", "", "error");
            return;
        }

        if(maxDistance.value === 0 || maxDistance.value < 1){
            await Alert("Informe uma distância máxima entre Pontos de Venda em um mesmo combo válida", "", "error");
            return;
        }

        if(expirationDate.value === ""){
            await Alert("Por favor, informe uma data de expiração válida", "", "error");
            return;
        }

        var error = surveys.filter((s) => s.title === "");

        if(error.length > 0){
            error = [];
            return await Alert("Por favor, insira uma pergunta válida!", "Dados inválidos", "error");
        }

        var pointOfSales = selectedPdvs.map((pvd) =>(
            {
                id: pvd.id,
                latitude: pvd.latitude,
                longitude: pvd.longitude,
                city: pvd.city
            }
        ));

        var payload = {
            pointOfSales: pointOfSales,
            maxDistance: parseFloat(maxDistance.value)
        }

        setLoading(true);

        await service.post("/api/route/simulatecombos", payload)
        .then(async response => {

            if(response.status === 200){

                if(response.data.data === null){
                    Alert("Um erro ocorreu!", response.data.title, "error");
                    return;
                }

                var items = response?.data?.data?.map((item, i) => {

                    return {
                        id: item.name,
                        city: item.city,
                        pointOfSaleNumber: pdvsTooltip(item.pointOfSales, item.pointOfSales.length),
                        pointOfSales: item.pointOfSales,
                        distanceFix: item.distance.toFixed(2),
                        distance: item.distance,                        
                    }
                })
                setAllData(items);
                setSimulationModal(true);
            }
        }).finally(() => {
            setLoading(false);            
        });
    }

    const getComboPayload = () => {
        var items = confirmedModalData.map((item) => 
        ({
            name: item.id,
            city: item.city,
            typeName: item.typeName,
            pointOfSales: item.pointOfSales,
            distance: item.distance
        }));

        var surveyList = surveys.map((survey) => 
        ({
            title: survey.title,
            number: survey.number,
            answerType: parseInt(survey.answerType),
            aiFilterKeywords: survey.AIFilterKeywords,
            value: parseInt(pdvValue.value)
        }));
        
        return {
            routes: items,
            questions: surveyList,
            expirationDate: expirationDate.value,
            campaignsIds: selectedCampaigns.map(c => c.value),
            usersGroupsIds: selectedUserGroups.map(c => c.value)
        };
    }

    const confirmCombo = async() => {      
        if(confirmedModalData.length === 0){
            await Alert("Selecione ao menos um combo para prosseguir!", "", "error");
            closeSimulationModal();
            return;
        }

        if(confirmedModalData.length === 0){
            await Alert("Selecione ao menos um combo para prosseguir!", "", "error");
            closeSimulationModal();
            return;
        }

        var payload = getComboPayload();

        await service.post("/api/route/createcombos", payload)
        .then(async response => {

            if(response.status === 200){
                if(response.data.status === 200)
                    Alert("Combo(s) gerados com sucesso!", "", "success");
                else
                    Alert("Um erro ocorreu", response.data?.title, "error");
                // setConfirmedSurveys(response.data.data.questions);
                // setOpenResponseModal(true);

                var items = [];

                selectedPdvs.map(pdvsSelected => {

                    var dontSelected = true;
                    confirmedModalData.map(combo => {
                        combo.pointOfSales.map(pdvId => {

                            if(pdvsSelected.id === pdvId)
                                dontSelected = false;
                        })
                    })
                    
                    if(dontSelected)
                        items.push(pdvsSelected)
                })

                setSelectedPdvs([...items]);
                setConfirmedModalData([]);

            }else{ 
                Alert("Um erro ocorreu!", response.data.title, "error");
            }
            closeSimulationModal();
        })
    }

    const getPresetPayload = (payload) => {
        payload.distance = distanceFilter.value;
        payload.maxDistance = maxDistance.value;
        payload.pointsOfSaleFilterState = stateFilter;
        payload.pointsOfSaleCityFilter = cityFilter;
        payload.pointsOfSaleRegionFilter = regionFilter;
        payload.pointsOfSaleLatitudeFilter = latitude;
        payload.pointsOfSaleLongitudeFilter = longitude;
        payload.pointsOfSaleSelected = selectedPdvs.map(({ authorization, ...rest }) => rest);

        if(typeFilter && typeFilter !== "0") {
            payload.pointsOfSaleTypeIdFilter = typeFilter;
        }

        return payload;
    }

    const handleCreatePreset = async (presetName) => {
        var payload = getComboPayload();
        payload = getPresetPayload(payload);
        payload.name = presetName;

        await service.post(`/api/RoutePreset/Create`, payload)
            .then(response => {
                if (response.status === 200 && response.data.status === 200) {
                    toast.success(`Preset de Combo criado com sucesso!`);    
                } else {
                    toast.error("Não foi possível criar o preset de combo, por favor tente novamente mais tarde!");
                }

                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
            setRoutePresetConfirmationIsOpen(false);
            getPresets();
    }

    const handleUpdatePreset = async () => {
        setLoading(true);
        var payload = getComboPayload();
        payload = getPresetPayload(payload);

        await service.put(`/api/RoutePreset/Edit/${presetFilter}`, payload)
            .then(response => {
                if (response.status === 200 && response.data.status === 200) {
                    toast.success(`Preset de Combo atualizado com sucesso!`);    
                } else {
                    toast.error("Não foi possível atualizar o preset de combo, por favor tente novamente mais tarde!");
                }

                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
            setRoutePresetConfirmationIsOpen(false);
            getPresets();
    }

    const deletePreset = async () => {
        if(loading)
            return;
        
        setLoading(true);

        await service.delete(`/api/RoutePreset/delete/${presetFilter}`)
            .then(async response => {
                if (response.status === 200 && response.data.status === 200) {
                    toast.success(`Preset de Combo removido com sucesso!`);
                    setPresetFilter(-1);
                } else {
                    toast.error("Não foi possível deletar o preset de combo, por favor tente novamente mais tarde!");
                }
                getPresets();
                cleanInputs();
            });
        setLoading(false);
    }

    const closeSimulationModal = () => {
        setAllData([...data, ...confirmedModalData])
        setConfirmedModalData([])
        setSimulationModal(false);
    }

    const pdvsTooltip = (pdvs, amount) => {
        return (<div className="hasTooltip">
            <p className="">{amount}</p>
            <div className="tooltip-pvd-parent">
            {
                pdvs.map((pvd, i) => {
                    var pvdInfo = selectedPdvs.find(slctd => slctd.id == pvd);
                    
                    return (<div className="tooltip-pdv-item" key={pvd.id}>
                        <h3>{pvdInfo.name}</h3>
                        <span>{pvdInfo.address}, {pvdInfo.city} - {pvdInfo.state} - {pvdInfo.region}</span>
                        <p>{pvdInfo.type}</p>
                    </div>)
                })
            }
            </div>
        </div>);
    }
    
    const getPVDs = async (currentPage = 0, currentPageSize = 10, sortColumn = null, orderDirection = 0) => {

        setLoading(true);

        var filters = [];

        if(cityFilter !== "")
            filters.push({ name: 'city', value: cityFilter })

        if(regionFilter !== "")
            filters.push({ name: 'region', value: regionFilter })

        if(typeFilter !== "0")
            filters.push({ name: 'type', value: typeFilter })

        if(stateFilter !== -1 && stateFilter !== '-1')
            filters.push({ name: 'state', value: stateFilter })    

        if((latitude !== 0 && longitude !== 0) && (distanceFilter.value > 0)){
            filters.push({
                name: 'latitude',
                value: latitude.toString()
            })

            filters.push({
                name: 'longitude',
                value: longitude.toString()
            })

            filters.push({
                name: 'range',
                value: distanceFilter.value.toString()
            })
        }

        filters.push({ name: 'isActive', value: true })
            
        var payload = {
            "sortColumn": null,
            "sortColumnDirection": 0,
            // "pageSize": currentPageSize !== null ? currentPageSize : pageSize,
            "pageSize": 1000,
            "skip": currentPage * (currentPageSize !== null ? currentPageSize : pageSize),
            "filters": filters
        }

        await service.post("/api/pointofsale/list", payload)
        .then(async response => {

            if(response.data.data == null){
                Alert("Um erro ocorreu!", "", "error");
                return;
            }

            var items = response?.data?.data?.data.map(c => {
                return {
                    id: c.id,
                    name: c.name,
                    region: c.region,
                    city: c.city,
                    type: c.typeName,
                    PDVType: c.typeName,
                    latitude: c.latitude,
                    longitude: c.longitude,
                    address: c.address,
                    state: c.state,
                    authorization: getAuthorizationColumn(c.id, c.authorization)
                }
            })

            setData(items);
            var totalAmount = response.data.data.recordsFiltered;
            setRecordsFiltered(totalAmount);   

            if (firstLoad)
                setFirstLoad(false);

        }).finally( () => {
            setLoading(false);            
        });
    }

    const isSelected = (items, key) => {
        if(items === undefined)
            return false;
 
        return items.find(item => item.id === key) !== undefined;
     };

    const handleTableCheckChange = (list, selecteds, setSelectedData) => {
        var currentList = list?.slice(pageCurrent * pageSize, (pageCurrent * pageSize) + pageSize);

        var selecteds = currentList.filter((item) => isSelected(selecteds, item.id));
        if(selecteds.length === pageSize){
            let filteredList = [...selectedPdvs];          

            selecteds.forEach((item) => {
                
                const index = filteredList.indexOf(item);
                
                if(index != undefined) {
                    filteredList.splice(index, 1);
                }
            });
            
            setSelectedData(filteredList);
            // toast.success("PDVs removidos da seleção");
        }      
        else{
            var tempList = [...selectedPdvs]

            currentList.forEach((item) => {
                if(!isSelected(selecteds, item.id))
                tempList.push(item);
            });

            setSelectedData([...tempList]);
            // toast.success("PDVs adicionados à seleção");
        }
    }
    
    return (
        <Fragment>
            <Breadcrumb parent="Combos" title="Gerar" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header color-primary">
                                <div className="flex space-between">
                                    <h2>Gerar Combos</h2>
                                    <div className="flex">

                                        <div>
                                            <label className="label-filter" htmlFor="preset-select">Preset selecionado</label>
                                            <div className='flex alg-end'>
                                                <select value={presetFilter} style={{width: '35vw'}} name='preset-select' onChange={({target}) => {
                                                        setPresetFilter(target.value);
                                                        getPresetContent(target.value);
                                                    }}>
                                                    <option value={-1}>Selecione</option>
                                                    {presets.map((option, index) =>
                                                        <option key={option.id} value={option.value}>
                                                            {option.name}
                                                        </option>
                                                    )}
                                                </select>
                                                {presetFilter !== -1 && <span onClick={cleanInputs} className="pointer gray-color ml-5">
                                                    <X/>
                                                </span>}
                                            </div>
                                            <p className='error-color pointer w-fit-content' onClick={deletePreset}>{presetFilter !== -1 ? 'Excluir Preset' : ''}</p>
                                        </div>
                                        <button  className="btn btn-pill btn-secondary ml-50" type="submit"
                                                onClick={() => {
                                                    validateFields();
                                                    if(pdvValue.validate() && distanceFilter.validate() && maxDistance.validate() && expirationDate.validate()) {
                                                        if(presetFilter === -1) {
                                                            setRoutePresetConfirmationIsOpen(true);
                                                        } else {
                                                            handleUpdatePreset()
                                                        }
                                                    }
                                                }}>
                                            {presetFilter === -1 ? 'Criar ' : 'Atualizar '} Preset de Combo
                                        </button>
                                    </div>
                                </div>
                                <div className="flex">                                
                                <div className="fields-filter">
                                    <div className="field-filter-item">
                                        <label className="label-filter" htmlFor="state">Estado</label>
                                        <br/>
                                        <select onChange={({target}) => setStateFilter(target.value)} defaultValue={stateFilter} value={stateFilter} style={{width: '190px'}}>
                                            <option value={-1}>Selecione</option>
                                            {states.map((option, index) =>
                                                <option key={option.value} value={option.value}>
                                                    {option.name}
                                                </option>
                                            )}
                                        </select>
                                        <br/>
                                    </div>

                                    <div className="field-filter-item">
                                        <label className="label-filter" htmlFor="city">Cidade</label>
                                        <br/>
                                        <input className="form-control" name="city" type="text" placeholder="Cidade" id="city" value={cityFilter} onChange={({target}) => setCityFilter(target.value)} />
                                    </div>

                                    <div className="field-filter-item">
                                        <label className="label-filter" htmlFor="region">Região</label>
                                        <br/>
                                        <input className="form-control" name="region" type="text" placeholder="Região"  id="region" value={regionFilter} onChange={({target}) => setRegionFilter(target.value)} />
                                    </div>

                                    <div className="field-filter-item">
                                        <label className="label-filter" htmlFor="latitude">Latitude</label>
                                        <br/>
                                        <Input className="form-control" name="latitude" type="number" placeholder="Latitude"  id="latitude" value={latitude} onChange={({target}) => setLatitude(target.value)} cleanContent={true} handleCleanContent={() => setLatitude(0)} />
                                    </div>

                                    <div className="field-filter-item">
                                        <label className="label-filter" htmlFor="longitude">Longitude</label>
                                        <br/>
                                        <Input className="form-control" name="longitude" type="number" placeholder="Longitude"  id="longitude" value={longitude} onChange={({target}) => setLongitude(target.value)} cleanContent={true} handleCleanContent={() => setLongitude(0)} />
                                    </div>

                                    <div className="field-filter-item">
                                        <label className="label-filter" htmlFor="type">Tipo</label>
                                        <br/>
                                        <select onChange={({target}) => setTypeFilter(target.value)} style={{width: '190px'}} defaultValue="0" value={typeFilter}>
                                            <option value="0">Selecione</option>
                                            {pvdTypes.map((option, index) =>
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                            )}
                                        </select>
                                    </div>  

                                    <div className="centralize">
                                        <button className="btn btn-pill btn-outline-secondary" type="button" onClick={() => {
                                            setCurrentPage(0);
                                            getPVDs();
                                        }}>Filtrar PDVs</button>
                                    </div>
                                    
                                    </div>

                                    <div style={{width: '73%'}}>
                                        
                                        {<GoogleApiWrapper showPDVTypeLegend={true} setLatitude={setLatitude} setLongitude={setLongitude} lat={latitude} lon={longitude} searchedItems={data} alwaysRender={true}></GoogleApiWrapper> }

                                        <div className="flex mt-20">
                                            <div className="field-filter-item">
                                                <label className="label-filter" htmlFor="distance">Raio (m)</label>
                                                <br/>
                                                <Input className="form-control" name="distance" type="number" placeholder="Raio (metros)"  id="distance" required={true} {...distanceFilter} />
                                            </div>

                                            <div className="field-filter-item">
                                                <label className="label-filter" htmlFor="value">Valor por PDV (R$)</label>
                                                <br/>
                                                <Input className="form-control" name="value" type="number" placeholder="0.00"  id="value" required={true} {...pdvValue} />
                                            </div>

                                            <div className="field-filter-item">
                                                <label className="label-filter" htmlFor="maxDistance">Distância máx. PDVs (m)</label>
                                                <br/>
                                                <Input className="form-control" name="maxDistance" type="number" placeholder="Distância máx. (metros)"  id="maxDistance" required={true} {...maxDistance} />
                                            </div>

                                            <div className="field-filter-item">
                                                <label className="label-filter" htmlFor="expirationDate">Expiração dos combos</label>
                                                <br/>
                                                <div className="flex alg-end">
                                                    <Input className="form-control" name="expirationDate" type="date" cleanContent={true} id="expirationDate" required={true} min={new Date().toISOString().split('T')[0]} {...expirationDate} handleCleanContent={() => expirationDate.setValue('')} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                               
                                </div>
                            </div>

                            {/* TABLES */}
                            <div className="combos-table-container">
                                <div className="table-item">
                                    <h3 className="color-primary">Pontos de Venda</h3>

                                    {data === null && !loading &&
                                        <div className="align-center">
                                            <div className="empty-list-txt">Realize uma busca para realizar a seleção!</div>
                                            <img className="empty-list-img" style={{paddingTop: '20px'}} src={emptyList} alt="empty list" />
                                        </div>
                                    }

                                    {data != null && !loading && data.length === 0 &&
                                    <div className="align-center">
                                        <div className="empty-list-txt">Nenhum PDV encontrado</div>
                                        <br/>
                                        <img className="empty-list-img" src={emptyList} alt="empty list" />
                                    </div>
                                    }

                                    {loading &&
                                        <div className="loader-box loading">
                                            <div className="loader">
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                            </div>
                                        </div>
                                    }

                                    <div className="datatable-react" style={{display: loading ? 'none' : 'block'}}>
                                        {/* totalPages={Math.ceil(recordsFiltered/pageSize)} */}
                                        {data?.length > 0 && <Datatable
                                            multiSelectOption={true}
                                            myData={data}
                                            loading={false}
                                            pageCurrent={pageCurrent}
                                            pageSize={pageSize}
                                            pagination={true}
                                            class="-striped -highlight"
                                            columns={columns}
                                            totalPages={Math.ceil(data.length/pageSize)}
                                            setPageSize={setPageSize}
                                            selectedData={selectedPdvs}
                                            manual={false}
                                            handleTableCheckChange={() => handleTableCheckChange(data, selectedPdvs, setSelectedPdvs)}
                                            setSelectedData={setSelectedPdvs}  
                                            setCurrentPage={setCurrentPage} 
                                            currentPage={pageCurrent}
                                            checkboxDelete={false}     
                                        />}
                                    </div>
                                </div>

                                <div className="table-center">
                                    <div className="arrow-right"></div>
                                </div>

                                <div className="table-item">
                                    <h3 className="color-primary"><b>Pontos de Venda Selecionados</b></h3>

                                    {selectedPdvs.length === 0 &&
                                    <div className="align-center">
                                        <div className="empty-list-txt">Nenhum PDV selecionado</div>
                                        <img className="empty-list-img" src={require("../../../assets/images/none-selected.svg")} alt="empty list" />
                                    </div>
                                    }

                                    {selectedPdvs.length > 0 && <div className="datatable-react">
                                        <br/>
                                        <br/>
                                        <Datatable
                                            myData={selectedPdvs}
                                            loading={loading}
                                            firstLoad={firstLoad}
                                            pageSize={pageSize}
                                            pagination={true}
                                            currentPage={selectedTablePage}
                                            setCurrentPage={setSelectedTablePage}
                                            class="-striped -highlight"
                                            columns={columns}
                                            totalPages={Math.ceil(selectedPdvs.length/pageSize)}
                                            listOf="PDV"
                                            setSelectedData={setSelectedPdvs}
                                            setPageSize={setPageSize}
                                            manual={false}
                                            removeOption={true}
                                            handleTableCheckChange={() => handleTableCheckChange(selectedPdvs, [], setSelectedPdvs)}
                                        />
                                    </div>}
                                </div>
                            </div>    

                            <div className="grid-col2">
                                <div className="table-item">
                                    <h3 className="color-primary"><b>Campanha{selectedCampaigns.length !== 1 && 's'}</b></h3>
                                    {selectedCampaigns.length > 0 &&
                                        <div className='flex mb-10 mt-10'>
                                            {selectedCampaigns.map(p => <p className='tag-primary' key={p.value}>{p.label}</p>)}
                                        </div>
                                    }
                                    <div style={{maxWidth: '600px'}}>
                                        <MultiSelect
                                            options={campaigns}
                                            overrideStrings={{
                                                selectSomeItems: "Selecione",
                                                allItemsAreSelected: "Todas as campanhas",
                                                selectAll: "Selecionar todas",
                                                search: "Buscar",
                                            }}
                                            isLoading={false}
                                            value={selectedCampaigns}
                                            onChange={setSelectedCampaigns}
                                        />
                                    </div>
                                </div>

                                <div className="table-item">
                                    <h3 className="color-primary"><b>Grupo{selectedUserGroups.length !== 1 && 's'} de Usuários</b></h3>
                                    {selectedUserGroups.length > 0 &&
                                        <div className='flex mb-10 mt-10'>
                                            {selectedUserGroups.map(p => <p className='tag-primary' key={p.value}>{p.label}</p>)}
                                        </div>
                                    }
                                    <div style={{maxWidth: '600px'}}>
                                        <MultiSelect
                                            options={usersGroups}
                                            overrideStrings={{
                                                selectSomeItems: "Selecione",
                                                allItemsAreSelected: "Todas os grupos de usuários",
                                                selectAll: "Selecionar todos",
                                                search: "Buscar",
                                            }}
                                            isLoading={false}
                                            value={selectedUserGroups}
                                            onChange={setSelectedUserGroups}
                                        />
                                    </div>
                                </div>
                            </div>
                                                        
                            <div className="table-item">
                                <div className="flex pl-10">
                                    <h3 className="color-primary"><b>Questionário</b></h3>
                                    <span className="add-item" onClick={addSurvey}> 
                                        <PlusCircle />
                                    </span>
                                </div>
                                
                                <Surveys items={surveys} setItems={setSurveys} />
                            </div>

                            <div className="centralize">
                                <button className="btn btn-pill btn-secondary" type="button" onClick={sendSimulation}>Simular</button>
                            </div>
                            <br/>

                        </div>
                    </div>

                    {/* Simulation Modal */}
                    <SimulationModal 
                    isOpen={simulationModal} 
                    loading={false} 
                    toggle={closeSimulationModal} 
                    data={allData} 
                    setData={setAllData}
                    confirmCombo={confirmCombo} 
                    confirmedPDVs={confirmedModalData} 
                    setConfirmedPDVs={setConfirmedModalData} 
                    />

                    {/* Confirmation Modal */}
                    <SimulationResponse toggle={() => {setOpenResponseModal(false)}} isOpen={openResponseModal} loading={false} data={confirmedSurveys} status={200} />

                    <RoutePresetConfirmationModal 
                        isOpen={routePresetConfirmationIsOpen}
                        toggle={ async (event, success = null, message) => {
                            setRoutePresetConfirmationIsOpen(false);
                        }}
                        handleCreatePreset={handleCreatePreset}
                    />
                </div>
            </div>
            
            <ToastContainer />
        </Fragment>
    );
};

export default CombosGenerate;